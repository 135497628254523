import React from 'react'
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import './Pricing.css';
import CardList from '../Theams/CardList';

const Pricing = () => {
  return (
    <>
 
    <Header/>

    <h1 className="mb-4 text-center">Sample Card's</h1>
    <CardList/>
    




    <div className="pricing-table">
      
      <h2>Pricing Information</h2>
      <table>
        <thead>
          <tr>
            <th>Card Type</th>
            <th>Price (INR)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>One Card</td>
            <td>2000</td>
          </tr>
          <tr>
            <td>Five Cards</td>
            <td>1500 / each</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Footer />
   </>
  )
}

export default Pricing